"use client";

import React, { useRef, useState } from "react";
import { useRouter } from "next/router";
import { motion, AnimatePresence } from "framer-motion";
import styles from "styles";
import { navVariants } from "utils/motion";
import { en, fr, ar } from "translation";
import useStore from "@/store";
import useClickOutside from "@/hooks/useClickOutside";

const menuVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.8, transition: { duration: 0.1 } },
};

const Navbar = () => {
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : locale === "fr" ? fr : ar;
  const isLoggedIn = useStore(state => state.isLoggedIn);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [togglelangMenu, setTogglelangMenu] = useState(false);
  const navMenuRef = useRef();
  const lanMenuRef = useRef();

  useClickOutside(navMenuRef, () => setToggleMenu(false));
  useClickOutside(lanMenuRef, () => setTogglelangMenu(false));

  return (
    <motion.nav
      variants={navVariants}
      initial="hidden"
      whileInView="show"
      className={`${styles.xPaddings} py-8 relative z-[12]`}
    >
      <div className="absolute w-[50%] inset-0 gradient-01 z-0" />
      <div
        className={`${styles.innerWidth} mx-auto flex justify-between gap-8 items-center`}
      >
        <div ref={lanMenuRef} className="lang z-[99999]">
          {togglelangMenu ? (
            <img
              src="/arrow_lang.svg"
              alt="languages"
              className="w-[32px] h-[32px] object-contain cursor-pointer"
              onClick={() => setTogglelangMenu(false)}
            />
          ) : (
            <img
              src="/globe.svg"
              alt="languages"
              className="w-[32px] h-[32px] object-contain cursor-pointer"
              onClick={() => setTogglelangMenu(true)}
            />
          )}
          <AnimatePresence>
            {togglelangMenu && (
              <motion.div
                key="langMenu"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={menuVariants}
                className="relative"
              >
                <div className="bg-white px-10 py-5 z-999 rounded-md absolute top-5 text-left left-1 uppercase font-semibold text-slate-700 h-[200px] gap-4 flex flex-col items-stretch justify-around">
                  <p className="Whitespace-nowrap">
                    <a href="/en" onClick={() => setTogglelangMenu(false)}>
                      English
                    </a>
                  </p>
                  <p className="Whitespace-nowrap">
                    <a href="/fr" onClick={() => setTogglelangMenu(false)}>
                      Français
                    </a>
                  </p>
                  <p className="Whitespace-nowrap">
                    <a href="/ar" onClick={() => setTogglelangMenu(false)}>
                      العربية
                    </a>
                  </p>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <img
          src="/logocnw.svg"
          alt="Digital Home"
          className="w-[200px] h-auto cursor-pointer"
        />

        <div ref={navMenuRef} className="menu z-[99999]">
          {toggleMenu ? (
            <img
              src="/close.svg"
              alt="menu"
              className="w-[24px] h-[24px] object-contain cursor-pointer"
              onClick={() => setToggleMenu(false)}
            />
          ) : (
            <img
              src="/menu.svg"
              alt="menu"
              className="w-[24px] h-[24px] object-contain cursor-pointer"
              onClick={() => setToggleMenu(true)}
            />
          )}
          <AnimatePresence>
            {toggleMenu && (
              <motion.div
                key="navMenu"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={menuVariants}
                className="relative"
              >
                <div className="bg-white shadow px-10 py-5 z-20 rounded-md absolute top-5 text-right right-1 uppercase font-semibold text-slate-700 gap-6 flex flex-col items-stretch justify-around">
                  <p className="whitespace-nowrap">
                    <a href="#home" onClick={() => setToggleMenu(false)}>
                      {t.home}
                    </a>
                  </p>
                  <p className="whitespace-nowrap">
                    <a href="#about" onClick={() => setToggleMenu(false)}>
                      {t.about}
                    </a>
                  </p>
                  <p className="whitespace-nowrap">
                    <a href="#explore" onClick={() => setToggleMenu(false)}>
                      {t.products}
                    </a>
                  </p>
                  <p className="whitespace-nowrap">
                    <a href="#getstarted" onClick={() => setToggleMenu(false)}>
                      {t.getstarted}
                    </a>
                  </p>
                  <p className="whitespace-nowrap">
                    <a href="#whatsnew" onClick={() => setToggleMenu(false)}>
                      {t.whatsnew}
                    </a>
                  </p>
                  <p className="whitespace-nowrap">
                    <a
                      href={isLoggedIn ? "/dashboard" : "/login"}
                      onClick={() => setToggleMenu(false)}
                    >
                      {isLoggedIn ? t.account : t.login}
                    </a>
                  </p>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </motion.nav>
  );
};

export default Navbar;
