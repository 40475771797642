export const en = {
  home: "Home",
  about: "About",
  products: "Products",
  whatsnew: "What's New",
  login: "Login",
  getstarted: "Get Started",
  startselling: "Start Selling",
  account: "Account",
  rights: "All Rights Reserved",
  catalogue: "Catalog",
  morethan: "More than",
  productshero: "products",
  onestop: "One shop, One Stop",
  onesupplier: "One Supplier",
  aboutsection:
    "The first Algerian platform that offers digital shopping in your neighborhood with postpaid mainstream business experience. Digital Home helps you get your risk-free business going with steady supply of products and services.",
  explore_our_collection: "Explore our Collection Of",
  howitworks: "How it Works?",
  getstartedinfew: "Get started with just a few steps",
  whatsnewindh: "What's new about Digital Home?",
  firststep:
    "Create an account as a dealer, & our customer service team will call you.",
  secondstep: "Sign a contract with our company to get postpaid credit.",
  thirdstep: "Login to your account dashboard and start selling.",
  title1: "No Investment Required",
  subtitle1:
    "We are offering a rich catalog of digital products and services for you to sell & gain your margin with no prior investment.",
  title2: "Intuitive & Friendly Interface",
  subtitle2:
    "Our dashboard is designed to help you sell, track credit and show products to your clients.",
  egift: "E-Gift Cards",
  subscription: "Online Subscriptions",
  recharge: "Mobile Recharge",
  gaming: "Gaming Cards",
  software: "Software Licenses",
};
export const fr = {
  home: "Accueil",
  about: "A Propos",
  products: "Produits",
  whatsnew: "Nouveautés",
  getstarted: "Commencer",
  startselling: "Commencer à vendre",
  account: "Compte",
  login: "Se connecter",
  rights: "Tous les droits sont réservés",
  catalogue: "Catalogue",
  morethan: "Plus que des",
  productshero: "produits",
  onestop: "Un magasin, Un Guichet Unique",
  onesupplier: "Un Fournisseur",
  aboutsection:
    "La première plateforme algérienne qui propose des achats numériques dans votre quartier avec une expérience commerciale postpayée destinée aux grand public . Digital Home vous aide à démarrer votre entreprise sans risque avec un approvisionnement régulier en produits et services.",
  explore_our_collection: "EXPLOREZ NOTRE COLLECTION DE",
  subscription: "Abonnement Enligne",
  howitworks: "Comment ça Fonctionne?",
  getstartedinfew: "Commencez en quelques étapes",
  whatsnewindh: "Quoi de neuf chez Digital Home ?",
  firststep:
    "Créez un compte en tant que revendeur et notre équipe de service client vous appellera.",
  secondstep:
    "Signez un contrat avec notre société pour obtenir un crédit postpayé.",
  thirdstep:
    "Connectez-vous au tableau de bord de votre compte et commencez à vendre.",
  title1: "Aucun investissement requis",
  subtitle1:
    "Nous vous proposons un riche catalogue de produits et services numériques pour vous permettre de vendre et de gagner votre marge sans investissement préalable.",
  title2: "Interface intuitive et conviviale",
  subtitle2:
    "Notre tableau de bord est conçu pour vous aider à vendre, suivre le crédit et montrer les produits à vos clients.",
  egift: "Cartes-cadeaux électroniques",
  subscription: "Abonnements en ligne",
  recharge: "Recharge Mobile",
  gaming: "Cartes de jeu",
  software: "Licences logicielles",
};

export const ar = {
  home: "الرئيسية",
  about: "حول الشركة",
  products: "المنتجات",
  whatsnew: "تحديثات",
  getstarted: "إنطلق الان",
  startselling: "إبدأ البيع",
  account: "الحساب",
  login: "تسجيل الدخول",
  rights: "جميع الحقوق محفوظة",
  catalogue: "الكتالوج",
  morethan: "ليست مجرد",
  productshero: "منتجات",
  onestop: "محل واحد، محطة واحدة",
  onesupplier: "مورد واحد",
  aboutsection:
    "أول منصة جزائرية توفر تجربة التسوق الرقمي محليا بنظام الدفع الآجل الموجهة للجميع، تساعدك منصة ديجيتال هوم على إنشاء تجارتك بشكل آمن مع إمداد ثابت ومستقر للمنتجات والخدمات",
  explore_our_collection: "إستكشف باقتنا من",
  subscription: "إشتراكات أونلاين",
  howitworks: "كيف يعمل الموقع؟",
  getstartedinfew: "ابدأ بخطوات قليلة فقط",
  whatsnewindh: "مالجديد في ديجيتال هوم؟",
  firststep:
    "قم بإنشاء حساب بصفتك تاجر ، وسيقوم فريق خدمة العملاء لدينا بالاتصال بك.",
  secondstep: "وقع عقدًا مع شركتنا للحصول على رصيد للدفع الآجل.",
  thirdstep: " قم بتسجيل الدخول إلى لوحة تحكم حسابك وابدأ البيع على الفور.",
  title1: "لا يوجد استثمار مطلوب",
  subtitle1:
    "نحن نقدم كتالوجًا غنيًا بالمنتجات والخدمات الرقمية لك لبيعها وكسب هامش ربحك بدون استثمار مسبق.",
  title2: "واجهة سهلة الاستخدام",
  subtitle2:
    "صُممت لوحة التحكم الخاصة بنا لمساعدتك في البيع وتتبع الرصيد وعرض المنتجات لعملائك.",
  egift: "بطاقات الهدايا الإلكترونية",
  subscription: "الاشتراكات عبر الإنترنت",
  recharge: "شحن الرصيد",
  gaming: "بطاقات الألعاب",
  software: "تراخيص البرمجيات",
};
